

/* @import '~@angular/material/prebuilt-themes/deeppurple-amber.css'; */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* @import '~@angular/material/prebuilt-themes/indigo-pink.css'; */
/* @import '~@angular/material/prebuilt-themes/pink-bluegrey.css'; */
/* @import '~@angular/material/prebuilt-themes/purple-green.css'; */

@import url('https://fonts.googleapis.com/css?family=Roboto');

.mat-drawer-content {
  height: 100% ;
}
.mat-sidenav-content{
  height: 100% ;
}

body, html {
  font-family: 'Roboto';
}
.mat-card {
  background-color: #f2f2f2;
}
.mat-tab-labels{
  background-color: #fff;
}
.mat-tab-label-container{
}
.mat-tab-body-wrapper{
  background-color: #fff;
}
/* .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #0060FF;
} */

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #1d2c67;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1d2c67;
}
.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
  background: #1d2c67;
}

.mat-toolbar.mat-primary {
  background: #1d2c67;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #1d2c67 !important;
}

.mat-ripple-element {
  background: rgba(27, 142, 241, .4) !important;
}

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.basic-container {
  padding: 30px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

.mat-form-field-infix {
  width: 225px;
}
.mat-form-field-flex {
  width: 225px;
}
.mat-form-field-underline {
  width: 225px;
}
.mat-drawer {
  background-color: #f2f2f2;
}
.footer {

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  Width: 100%;
  height: 40px;
   /* background-color: rgba(27, 142, 241, .4) !important; */
   background-color:  #1d2c67;
   /* display: block; */
   color: white;
   text-align: center;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: #000000;
}

.example-is-mobile .example-toolbar {
  position: fixed;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

h1.example-app-name {
  margin-left: 8px;
}

.example-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}

.example-is-mobile .example-sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
  flex: 1 0 auto;
}

.material-icons{
  font-family: 'Material Icons' !important;
}
